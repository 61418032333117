<template>
  <form @submit.prevent="submitBody">
    <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="$emit('close');"
    >
      <CRow>
        <CCol sm="12" lg="12">
          <CSelect
            size="sm"
            :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-8'}"
            addLabelClasses="text-right required"
            :label="$t('label.Event')"
            :options="EventOptions"
            v-model.trim="$v.EventId.$model"
            :value.sync="EventId"
            :is-valid="hasError($v.EventId)"
            :invalid-feedback="errorMessage($v.EventId)"
        /> 
        </CCol>
        <CCol sm="12" lg="12">
          <CSelect
            size="sm"
            :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-8'}"
            addLabelClasses="text-right"
            :label="$t('label.eirType')"
            :options="computedTpEirList"
            v-model.trim="$v.TpEirId.$model"
            :value.sync="TpEirId"
            :is-valid="hasError($v.TpEirId)"
            :invalid-feedback="errorMessage($v.TpEirId)"
        /> 
        </CCol>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            size="sm"
            :label="`${$t('label.name')} (ES)`"
            :placeholder="`${$t('label.eventForReason')} (ES)`"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="$v.EventReasonNameEs.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.EventReasonNameEs)"
            :invalid-feedback="errorMessage($v.EventReasonNameEs)"
          />
        </CCol>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            size="sm"
            :label="`${$t('label.name')} (EN)`"
            :placeholder="`${$t('label.eventForReason')} (EN)`"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="$v.EventReasonNameEn.$model"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.EventReasonNameEn)"
            :invalid-feedback="errorMessage($v.EventReasonNameEn)"
          />
        </CCol>
        <CCol sm="12" lg="12">
          <CRow class="mr-0">
              <label class="col-form-label-sm col-sm-12 col-lg-3 text-right pr-0 mb-2 pt-1 ">{{$t('label.client')}}</label>
              <CCol class="d-flex justify-content-end pr-0" sm="12" lg="auto">
                  <CSwitch
                      color="watch"
                      variant="3d"
                      type="checkbox"
                      size="sm"
                      :checked.sync="FgApplyClient"
                  />
              </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" lg="12" v-if="isEdit">
          <CSelect
            size="sm"
            :options="statusOptions"
            :value.sync="Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-2 col-lg-3', input:'col-sm-10 col-lg-8'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>  
      <template #footer>
          <CButton
              square
              color="add"
              class="d-flex align-items-center"
              @click.stop="isEdit ? statusConfirmation(eventReasonItems.FgActEventReason , Status, submitBody) : submitBody()"
              :disabled="isSubmit"
          >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>
          <CButton
              square
              color="wipe"
              class="d-flex align-items-center"
              @click="$emit('close');"
          >
          <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
          </CButton>
    </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import eventReasonFormValidations from '@/_validations/eventReason/eventReason';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    EventId: '',
    TpEirId: '',
    EventReasonId: '',
    EventReasonNameEs: '',
    EventReasonNameEn: '',
    FgEventList: 1,
    FgApplyClient: false,
    Status: 0,
    loading: false,
    tpEirItems: [],
    EventList: [],
  };
}

function submitBody() {
  try {
    this.isSubmit = true;
    this.$v.$touch();
    this.loading = true;
    if (this.$v.$error) {
      this.isSubmit = false;
      this.loading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let EventReasonJson = this.isEdit ? {
      EventReasonId: this.EventReasonId,
      EventId: this.EventId,
      TpEirId: this.TpEirId,
      EventReasonNameEs: this.EventReasonNameEs,
      EventReasonNameEn: this.EventReasonNameEn,
      FgEventList: this.FgEventList,
      FgApplyClient: this.FgApplyClient?1:0,
      Status: this.Status,
    }:{
      EventId: this.EventId,
      TpEirId: this.TpEirId,
      EventReasonNameEs: this.EventReasonNameEs,
      EventReasonNameEn: this.EventReasonNameEn,
      FgEventList: this.FgEventList,
      FgApplyClient: this.FgApplyClient?1:0,
    }

    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'EventReason-update':'EventReason-insert';
    this.$http
      .ejecutar(metodo, ruta, EventReasonJson,{ root: 'EventReasonJson'},)
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          const data = response.data.data[0];
          this.resetForm();
          this.$emit('close');
          this.$emit('Update');
          this.loading = false;
          this.isSubmit = false;
          this.notifySuccess({text: messageSuccess})
        } 
      }).catch((e) => {
        this.loading = false;
        this.isSubmit = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.loading = false;
    this.isSubmit = false;
    this.notifyError({text: e});
  }
}


async function getListApis() {
  this.loading = true;
  let peticiones =  
    [
      this.$http.ejecutar("GET", "Event-list", {EventGpoId: ''}),
      this.$http.ejecutar("GET", "TpEir-list", { Filter: 'ACTIVO'}),
    ];
   await Promise.all(peticiones)
    .then((responses) => {
      
      this.EventList = responses[0].data.data;
      this.tpEirItems = responses[1].data.data;
   

      this.loading = false;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

function getData(val) {
  this.EventReasonId = val.EventReasonId,
  this.TpEirId = val.TpEirId;
  this.EventId = val.EventId,
  this.TpEirId = val.TpEirId,
  this.EventReasonNameEs =  val.EventReasonNameEs;
  this.EventReasonNameEn = val.EventReasonNameEn;
  this.FgEventList = val.FgEventList,
  this.FgApplyClient = val.FgApplyClient,
  this.Status = val.FgActEventReason?1:0;

  this.$v.$touch();
}

function resetForm() {
  this.TpEirId = '';
  this.EventReasonId = '',
  this.EventId = '',
  this.EventReasonNameEs = '';
  this.EventReasonNameEn = '';
  this.FgEventList = 1,
  this.FgApplyClient = false,
  this.Status = 0;
  this.tpEirItems = [],
  this.EventList = [],
  this.loading = false,
  this.$v.$reset();
}

function statusSelectColor() {
  return this.Status === 1;
}



//computed
function EventOptions(){
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if(this.EventList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.EventList.map(function(e){
      chart.push({
        value: e.EventId, 
        label: e[`EventName${_lang}`],
      })    
    })
    return chart;
  }
}

function computedTpEirList(){
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if(this.tpEirItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.tpEirItems.map(function(e){
      chart.push({
        value: e.TpEirId, 
        label: e[`TpEirName${_lang}`],
      })    
    })
    return chart;
  }
}


export default {
  name: 'modal-event-reason',
  props: { modal: Boolean, eventReasonItems: Object, isEdit: Boolean, title: String},
  data,
  mixins: [
    ModalMixin,
  ],
  validations: eventReasonFormValidations,
  directives: UpperCase,
  methods: {
    submitBody,
    //getEventList,
    //getTpEirList,
    getListApis,
    getData,
    resetForm,
    statusSelectColor,
  },
  computed: {
    EventOptions,
    computedTpEirList,
  },
  watch: {
    modal: async function(val){
      this.modalActive = val;
      if (val) {
        await this.getListApis();
      }
      if (this.isEdit==true) {
        this.getData(this.eventReasonItems);
      }
    }
  },
 
};
</script>

