
import { 
    required, 
    minLength,
    maxLength,
    decimal } from "vuelidate/lib/validators";
import { onlyText} from '@/_validations/validacionEspeciales';

export default () => {
    return {
            EventId:{
                required,
            },
            TpEirId:{},
            EventReasonNameEs:{
                required,
                onlyText,
                minLength: minLength(5),
                maxLength: maxLength(250),
            },
            EventReasonNameEn:{
                required,
                onlyText,
                minLength: minLength(5),
                maxLength: maxLength(250),
            },
            FgEventList:{
                required,
            },
            FgApplyClient:{
                required,
            },

    }
}

